@import '../constants';

@mixin font-family($family) {
  @if $family == serif {
    font-family: Times, 'Times New Roman', serif;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  } @else if $family == sans-serif {
    font-family: 'Antique Legacy', 'Helevetica Neue', Helvetica, Arial,
      sans-serif;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  } @else if $family == monospace {
    font-family: 'Courier New', monospace;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  } @else {
    @warn 'Unknown font-family specified, please check for spelling mistakes';
  }
}

@mixin typo-normal-sans {
  @include font-family('sans-serif');
  font-size: var(--fs-normal);
  font-style: normal;
  font-weight: 500;
  line-height: var(--lh-normal);
}

@mixin typo-small-sans {
  @include font-family('sans-serif');
  font-size: var(--fs-small);
  font-style: normal;
  font-weight: 500;
  line-height: var(--lh-small);
}

@mixin typo-with-highlight {
  color: var(--color-secondary);

  strong {
    color: var(--color-primary);
    font-weight: normal;
  }
}

@mixin typo-link {
  a {
    color: inherit;

    .no-touchevents &:hover {
      color: var(--color-hover);
    }
  }
}

p {
  margin: 0 0 1em;

  &:last-child {
    margin-bottom: 0;
  }
}

a {
  color: inherit;
  text-decoration: none;
}
