@use 'sass:math';
@import 'style/mixins';

.Index {
  @include hide-scrollbars;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  &__inner {
    padding: 0 var(--padding-horizontal) var(--padding-vertical);

    .has-cookie-banner & {
      padding-bottom: var(--padding-vertical-cookie);
    }
  }

  &__item {
    margin-bottom: var(--grid-gutter);

    &:last-of-type {
      margin-bottom: 0;
    }

    .no-touchevents &:hover {
      .Index__item__meta,
      .Index__item__description {
        color: var(--color-primary);
      }

      .Index__item__image__wrapper {
        filter: none;
      }
    }

    &__grid {
      display: grid;
      grid-template-areas: 'meta image description';
      grid-template-columns: 1fr 1fr 1fr;
      gap: var(--grid-gutter);

      @include viewport-l {
        grid-template-areas: 'description image';
        grid-template-columns: 1fr 1fr;
      }

      @include viewport-m {
        grid-template-areas: 'description image';
        grid-template-columns: 1fr 1fr;
      }

      @include viewport-zero-to-s {
        grid-template-areas: 'image';
        grid-template-columns: 1fr;
      }
    }

    &__link {
      display: block;
      color: var(--color-secondary);
    }

    &__meta {
      @include typo-small-sans;
      grid-area: meta;
      color: var(--color-secondary);
      transition: $transition-index-text;

      @include viewport-l {
        display: none;
      }

      @include viewport-m {
        display: none;
      }

      @include viewport-zero-to-s {
        display: none;
      }
    }

    &__description {
      @include typo-small-sans;
      grid-area: description;
      height: 100%;
      color: var(--color-secondary);
      transition: $transition-index-text;

      @include viewport-zero-to-s {
        display: none;
      }
    }

    &__image {
      position: relative;
      grid-area: image;

      &__wrapper {
        position: relative;
        width: 100%;
        background: $color-grey-light;
        filter: $filter-index-img;
        transition: $transition-index-img;
        will-change: filter;
      }

      &__ratio {
        height: 0;
        padding-top: math.div(2, 3) * 100%;
        overflow: hidden;
      }
    }

    &__thumb {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__title {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      text-align: center;
      transform: translate3d(0, -50%, 0);

      &--has-thumb {
        color: $color-white;
      }
    }
  }
}
