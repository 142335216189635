@import 'style/mixins';

.Project {
  @include hide-scrollbars;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  &__btn {
    @include typo-small-sans;
    outline: 0;
    cursor: pointer;
    appearance: none;
    color: var(--color-primary);
    background: none;
    border: 0;
    outline: 0;
  }

  &__header {
    @include typo-small-sans;
    position: absolute;
    top: 0;
    left: 0;
    z-index: $z-index-project-header;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0 var(--grid-gutter);
    padding: var(--padding-vertical) var(--padding-horizontal) 0;

    @include viewport-zero-to-s {
      grid-template-columns: 1fr;

      &__center {
        display: none;
      }
    }

    &__left,
    &__center,
    &__right {
      justify-self: center;
    }

    &__left__small {
      display: none;

      @include viewport-zero-to-s {
        display: inline;
      }
    }
  }

  &__index {
    @include hide-scrollbars;
    @include typo-small-sans;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 100%;
    z-index: $z-index-project-index;
    background: $color-grey-light;
    padding: 34px var(--padding-horizontal) var(--grid-gutter);
    overflow: hidden scroll;

    @include viewport-zero-to-s {
      padding-top: 45px;
    }

    &__preview {
      display: flex;
      flex-flow: row wrap;
      margin-bottom: var(--grid-gutter);

      &__item {
        margin: 0 2px 2px 0;
        width: calc(var(--project-preview-height) * var(--r));
        height: var(--project-preview-height);

        &__btn {
          display: block;
          width: 100%;
          height: 100%;
          padding: 0;
          outline: 0;
          appearance: none;
          background: var(--color-secondary);
          border: 0;
          cursor: pointer;
        }

        &__img,
        &__video {
          width: 100%;
          height: 100%;
          filter: $filter-project-preview-img;
          transition: $transition-project-preview-img;

          .no-touchevents &:hover {
            filter: none;
          }
        }

        .Video {
          background: none;
        }

        .Video__video {
          object-fit: cover;
        }
      }
    }

    &__content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas: 'meta description';
      gap: var(--grid-gutter);
      color: var(--color-tertiary);

      &__meta {
        grid-area: meta;
      }

      &__description {
        grid-area: description;
      }

      @include viewport-zero-to-s {
        grid-template-columns: 1fr;
        grid-template-areas: 'description' 'meta';
      }
    }
  }

  &__slideshow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .swiper {
      height: 100%;
    }

    .swiper-wrapper {
      transition-timing-function: cubic-bezier(0.29, 0, 0.1, 0.99);
    }

    &__item {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &__button__prev,
    &__button__next {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 50%;
      height: 100%;
      cursor: none;

      .touchevents & {
        display: none;
      }
    }

    &__button__next {
      right: 0;
      left: auto;
    }
  }
}
