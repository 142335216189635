@import 'style/mixins';

#root {
  width: 100%;
  height: 100%;
}

.App {
  position: relative;
  width: 100%;
  height: 100%;

  &--loaded {
    transition: $transition-app;
  }

  &__home,
  &__project,
  &__index,
  &__about,
  &__contact,
  &__privacy,
  &__notfound {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &__project {
    top: 100%;
    background: $color-grey-light;
  }

  &__index {
    left: 100%;
  }

  &__about {
    top: 0;
    left: -100%;
  }

  &__privacy {
    top: 0;
    left: -200%;
  }

  &__contact {
    top: 0;
    left: 200%;
  }

  &__notfound {
    top: -100%;
    left: 0;
    background: var(--color-tertiary);
  }
}
